export default {
  fields: {
    items: 'Items #',
    copay: 'Copay',
    'copay-to-collect': 'Copay to collect',
    'copay-documents': 'Copay in documents',
    uid: 'Order Id',
    name: 'Recipient Name',
    email: 'Recipient E-mail',
    'rn-number-num': 'Rx Number №{num}',
    'rn-date-num': 'Filled Date №{num}',
    'rn-copay-num': 'Copay №{num}',
    'delivery-date': 'Date to Deliver',
    'delivery-time': 'Time to Deliver',
    'delivery-time-min': 'Delivery Time min',
    'delivery-time-max': 'Delivery Time max',
    'pickup-date': 'Pickup Date',
    'pickup-time-min': 'Pickup Time min',
    'pickup-time-max': 'Pickup Time max',
    'copay-instruction': 'Copay document instructions',
    'copay-instruction-hint': 'Sum of rx copay',
    'copay-received': 'Copay collected',
    'copay-prepaid': 'Copay is paid',
    'date-filled': 'Date filled',
    'email-num': 'Recipient E-mail №{num}',
    'remote-id': 'Remote Id',
    'call-arrival': 'Call Upon Arrival',
    'rph-tech': 'RPH/TECH',
    'date-of-incident': 'Date of Incident',
    custom: {
      method: 'Custom method',
      facility: 'Custom facility',
    },
    'operator-initials': 'Operator Initials',
    'operator-name': 'Technician`s Initials',
    'send-update-to': 'Send order updates to {email}',
    'special-instructions': 'Special Instructions',
    'at-station-instructions': 'At the Station Instruction',
    phone: {
      call: 'Call Phone',
      home: 'Home Phone',
      work: 'Work Phone',
    },
  },

  'rxs-title': 'Rx Numbers',
  'merge-order': 'Merge Order',
  'edit-dialog-title': 'Edit Order',
  'approve-method-title': 'Delivery Methods',
  'delivery-title': 'Delivery information',
  'package-title': 'Package Details',
  'documents-title': 'Documents to sign by Recipient (optional)',
  'recipient-title': 'Recipient Information',
  'additional-title': 'Additional Information',

  'type-available-hint': 'Available for {for}, until {until}',

  copay: {
    instructions: {
      1: 'Deliver the medication even if the patient does not pay the copay. IOU form will be signed.',
      2: 'Do not deliver the medication and bring it back to the pharmacy.',
      3: 'Do not deliver the medication and contact the pharmacy as soon as possible.',
    },
    type: {
      title: 'Copay Type',
      variants: {
        cash: 'Cash',
        check: 'Check',
      },
    },
    'validation-error': 'copay type not selected',
  },
  delivery: {
    success: 'Available for delivery',
    error: 'Out of coverage',
    toast: 'We do not deliver to this address!',
    'not-available': 'Delivery type is not available for this address',
    'carrier-paid-change-not-allowed': 'Changing the shipping type is not allowed for paid carrier shipping',
    'carrier-change-not-allowed-for-this-statuses': 'Changing the shipping type is not allowed for this statuses',
  },
  popups: {
    'merge-by-client': {
      title: 'Client <span class="bold">{client}</span> has an order created today',
      description: {
        'part-1': 'Client - {client} has ',
        'part-2': 'order created.',
        'part-3': 'Do you want merge current items with this order?',
      },
      buttons: {
        merge: {
          label: 'Merge',
        },
        discard: {
          label: 'Discard',
        },
      },
    },
  },
}
