const config = window.config()

export const apiV1 = `${config.apiSiteUrl}/v1`
export const apiGrapthQl = `${config.apiSiteUrl}/graphql`

export const {
  apiSiteUrl,
  recaptchaPublicKey,
  stripe,
  envDev,
  twilioSid,
} = config
