export default {
  gig: 'Route',
  gigs: 'Routes',
  gig_options: 'Route Options',
  optimize_gig: 'Optimize Route',
  gig_ready_to_start: 'Route ready to start',
  gig_not_selected: 'Route Not selected',
  gig_started: 'Route started',
  gig_on_time: 'Route On time',
  gig_late: 'Route late',
  bank_acc_gig_num: 'Bank Acc Route Num',
  please_choose_driver_or_route: 'Please choose Driver or Route',
  add_to_route: 'Add to Route',
  new_route: 'New Route',
  select_gig: 'Select Route',
  gig_is_required: 'Route is required',
  order_not_in_the_gig: 'Order not in the Route!',
}
