export default {
  btn: {
    cancel: 'Cancel',
    'hide-debug': 'Hide debug',
    'show-debug': 'Show debug',
    'view-order': 'View Order',
    saving: 'Saving, Please wait...',
    loading: 'Loading, Please wait...',
    add: 'Add',
    'add-print': 'Add/Print',
    reset: 'Reset',
    back: 'Go Back',
    save: 'Save',
    accept: 'Accept',
    'save-changes': 'Save Changes',
    create: 'Create',
    yes: 'Yes',
    send: 'Send',
    sending: 'Sending, Please wait...',
    'add-email': 'Add email',
    ok: 'OK',
    wait: 'Please wait...',
    close: 'Close',
    continue: 'Continue',
    select: 'Select',
  },
  phone: {
    settings: {
      title: 'settings',
      groups: {
        notifications: {
          title: 'Notifications',
          variants: {
            'auto-calls': 'Auto Call',
            'live-calls': 'Call',
            sms: 'SMS',
          },
        },
      },
    },
  },
}
