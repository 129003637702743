import { defineAsyncComponent } from 'vue'

export default {
  'table-page': defineAsyncComponent(() => import(/* webpackChunkName: "table" */ './table-page.vue')),
  'table-filters-right': defineAsyncComponent(() => import(/* webpackChunkName: "table" */ './table-filters-right.vue')),
  'table-filters-top': defineAsyncComponent(() => import(/* webpackChunkName: "table" */ './table-filters-top.vue')),
  'table-title': defineAsyncComponent(() => import(/* webpackChunkName: "table" */ './table-title.vue')),
  'table-pagination': defineAsyncComponent(() => import(/* webpackChunkName: "table" */ './table-pagination.vue')),
  'rx-table': defineAsyncComponent(() => import(/* webpackChunkName: "table" */ './rx-table.vue')),
}
