export default {
  list: {
    title: 'UPGRADE PLAN',
    'notification-ticket-enterprise': 'The ticket has been created. Please wait for our specialist to contact you!',
    periods: {
      monthly: 'Monthly',
      annually: 'Annually',
    },
    subscriptions: {
      starter: 'StarterRx',
      growth: 'GrowthRx',
      scale: 'ScaleRx',
      enterprise: 'Enterprise',
    },
    groups: {
      features: {
        title: 'Project Features',
        options: {
          drivers: {
            title: 'Drivers',
            starter: 'Up to <b>{count} Drivers</b>',
            growth: 'Up to <b>{count} Drivers</b>',
            scale: 'Over <b>{count} Drivers</b>',
            enterprise: 'Over <b>{count} Drivers</b>',
          },
          integrations: {
            title: 'Integrations',
          },
          'deliveries-on-map': {
            title: 'Visualize all deliveries on the map and draw the areas',
          },
          'route-optimization': {
            title: 'Efficient route optimization',
          },
          'proof-of-delivery': {
            title: 'Capture proof of delivery',
          },
          'interactive-tracing': {
            title: 'Real-time interactive tracking',
          },
          'personal-management': {
            title: 'Recipient Prescription Management Personal Portal',
          },
          scan: {
            title: 'Loose Prevention Barcode Scanning System',
          },
          'facility-management': {
            title: 'Multiple Facilities Management system',
          },
          'routes-management': {
            title: 'Recurrent Routes Management System',
          },
          copay: {
            title: 'Copay Management System',
          },
          'ai-route-planing': {
            title: 'AI Route Planing',
          },
          'staff-management': {
            title: 'Nested Staff Management',
          },
          'warehouse-management': {
            title: 'Facility and Warehouse Management',
          },
          qaqc: {
            title: 'Quality Assurance Management',
          },
          'driver-information-management': {
            title: 'Driver Information Management',
          },
          tips: {
            title: 'Tips Collection System for the Driver',
          },
          database: {
            title: 'Direct Database Access',
          },
          'order-tracking-api': {
            title: 'Orders creation/tracking API',
          },
          'order-webhook': {
            title: 'Order Status Change Webhook',
          },
          'driver-hiring-management': {
            title: 'Drivers Hiring Management System',
          },
        },
      },
      communication: {
        title: 'Communication',
        options: {
          'recipient-signature': {
            title: 'Anytime Recipient Signature Request',
          },
          'call-center': {
            title: 'Call center with live operator',
          },
          'sms-notification': {
            title: 'Send 3 SMS per delivery - Out for delivery, Driver on the way, Delivered',
          },
          'sms-communication': {
            title: 'Unlimited SMS for communication with patients',
          },
          feedback: {
            title: 'Customer Feedbacks',
          },
        },
      },
      branding: {
        title: 'Branding',
        options: {
          'pharmacy-label-tracking': {
            title: 'Your pharmacy branding on labels and tracking',
          },
          rx4route: {
            title: 'Rx4Route branding',
          },
          mobile: {
            title: 'Mobile Application Branding',
          },
        },
      },
      collaboration: {
        title: 'Collaboration',
        options: {
          dispatchers: {
            title: 'Dedicated full-time dispatchers',
          },
          'training-onboarding': {
            title: 'Premium training and onboarding of new staff',
          },
          support: {
            title: '24/7 Premium Support',
          },
          manager: {
            title: 'Dedicated manager',
          },
          development: {
            title: 'Dedicated Development of new features',
          },
          'dedicated-server': {
            title: 'Physically Isolated Server and Database',
          },
          tunneling: {
            title: 'Support IP2SEC Tunnels',
          },
        },
        analytics: {
          title: 'Analytics',
          options: {
            timeline: {
              title: 'Route based delivery timeline chart',
            },
            reports: {
              title: 'Monthly All Deliveries E-mail reports',
            },
            export: {
              title: 'Unlimited CSVExport',
            },
          },
        },
      },
      analytics: {
        title: 'Analytics',
        options: {
          timeline: {
            title: 'Route based delivery timeline chart',
          },
          reports: {
            title: 'Monthly All Deliveries E-mail reports',
          },
          export: {
            title: 'Unlimited CSVExport',
          },
        },
      },
      prices: {
        subscription: {
          title: 'Subscription Cost',
          mode: {
            monthly: 'month',
            annually: 'annually',
            enterprise: 'Contact Us',
          },
          buttons: {
            active: 'Active Plan',
            upgrade: 'Upgrade Now',
            contact: 'Contact Us',
          },
        },
      },
    },
  },
  detail: {
    title: 'Subscription management',
    status: {
      active: 'Active',
    },
    'subscription-include': 'Subscriptions include',
    buttons: {
      'edit-payment-methods': 'Edit Payment Information',
      'upgrade-plan': 'Upgrade plan',
    },
    period: {
      monthly: 'month',
      annually: 'year',
    },
    active: 'Subscription active for {period}. <br> Active till <b> {date} </b>.',
    'active-year': '1 year',
    'active-month': '30 days',
    left: '{count} days left',
    'drivers-limits': 'Deliveries: {current}/{max}',
    'payment-method': 'Payment method',
    'payment-method-description': 'Easily update your billing details for your subscription.',
  },
}
