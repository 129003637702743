import axios from 'axios'
import _ from 'lodash'
import Dropzone from 'dropzone'
import Swiper from 'swiper/bundle'
import L from 'leaflet'
import moment from 'moment-timezone'

import 'leaflet.markercluster'
import 'leaflet-contextmenu'
import 'leaflet.fullscreen/Control.FullScreen.js'
import '/assets/leaflet/leaflet-text-icon.js'
import 'leaflet-routing-machine'
import 'leaflet-draw'
import 'leaflet-editable'
import 'leaflet.pm'
import * as Mitt from '@/plugins/VueMitt.js'
///* <vue2>
import Vue from '@/plugins/VueControl.js'
// </vue2> */

L.Icon.Default.imagePath = '/assets/leaflet/images/'

window.Dropzone = Dropzone
window.Swiper = Swiper
window.Mitt = Mitt

window.axios = axios
window._ = _

window.moment = moment

///* <vue2>
if (Vue.config) Vue.config.productionTip = false
if (Vue.config) Vue.config.devtools = true
// </vue2> */

const setItem = (state, node, value) => {
  ///* <vue2>
  if (typeof state === 'object') Vue.set(state, node, value)
  // </vue2> */
  /* <vue3>
  state[node] = value
  // </vue3> */
}
const unsetItem = (state, node = 'item') => {
  ///* <vue2>
  Vue.set(state, node, null)
  Vue.delete(state, node)
  // </vue2> */
  /* <vue3>
  delete (state[node])
  // </vue3> */
}
const pushItem = (state, node = 'items', item = {}) => {
  if (_.isArray(state[node])) {
    state[node].push(item)
  }
}

const copy = text => {
  const el = document.createElement('textarea')
  el.value = text
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  document.body.removeChild(el)

  appJs.$toast(`You just copied: ${text}`, {
    type: 'success',
    timeout: 1500,
  })
}

const checkAndRemoveId = (pathname, regexp) => {
  const regex = new RegExp(regexp)

  if (regex.test(pathname)) {
    return pathname.replace(/\/\d+$/, '/')
  }
  return pathname
}

Dropzone.autoDiscover = false

const isRx4Route = ['adm.rx4route.lc', 'dashboard.rx4route.com', 'ph.rx4route.com', 'stage.rx4route.com'].includes(document.location.host)

export {
  Dropzone,
  setItem,
  unsetItem,
  pushItem,
  copy,
  checkAndRemoveId,
  isRx4Route,
}
