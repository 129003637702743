export default {
  title: 'Account',
  'menu-title': 'Account settings',
  menus: {
    activity: {
      title: 'Activity history',
    },
    'user-information': {
      title: 'User information',
      description: '',
    },
    basic: {
      title: 'Basic Information',
      fields: {
        client_id: {
          label: 'Client ID',
        },
        admin_id: {
          label: 'Admin ID',
        },
        forward_email: {
          label: 'Forward E-mail Address',
          placeholder: 'Your E-mail Address...',
        },
        address: 'Recipient Address',
        city: 'City',
        state: 'State',
        zip: 'ZIP',
        apt: 'Apt/Suite/Floor',
      },
    },
    subscriptions: {
      title: 'Subscriptions',
    },
    hours: {
      title: 'Business hours',
      description: "You can provide the company's operating hours here",
      days: {
        mo: 'Monday',
        tu: 'Tuesday',
        we: 'Wednesday',
        th: 'Thursday',
        fr: 'Friday',
        sa: 'Saturday',
        su: 'Sunday',
      },
      from: 'From',
      to: 'To',
      'is-same-every-day': {
        title: 'Work mode is the same every day',
      },
      actions: {
        reset: 'reset',
        'reset-without-weekend': 'reset without weekend',
        'fill-all': 'fill all',
        'fill-all-without-weekend': 'fill all without weekend',
      },
    },
    common: {
      title: 'Common Order Settings',
      description: 'Common Order Settings',
    },
    tracking: {
      title: 'Tracking Service Settings',
      description: 'Adjust your preferences for real-time delivery updates and notifications',
    },
    order: {
      title: 'Order Settings',
      description: 'Main Order Settings',
    },
    label: {
      title: 'Labels Settings',
      description: 'Print Labels Settings',
    },
    security: {
      title: 'Security',
      description: 'Manage your password settings and access controls for Two-Factor Authentication',
      inputs: {
        password: {
          title: 'New Password',
          placeholder: 'Update Your Password...',
          description: 'Password must be at least 8 characters long and contain numbers and special characters (! " " # $ % \' () *)',
        },
        confirm: {
          title: 'Confirm New Password',
          placeholder: 'Confirm New Password...',
        },
        'two-factor': {
          label: 'Enable Two-Factor Authentication',
          confirm: 'You will receive codes to {phone}',
        },
      },
    },
    sessions: {
      title: 'Sessions Confirmation',
      description: 'Control and confirm user access sessions for account protection',
      table: {
        ip: 'IP',
        os: 'Operation System',
        browser: 'Browser',
        time: 'Time',
        action: 'Action',
      },
    },
    webhook: {
      title: 'Orders Webhooks Settings',
      description: 'Set up webhooks for real-time order updates and integrations',
    },
    'driver-chat': {
      title: 'Driver Chat',
    },
    notifications: {
      title: 'Notifications',
      description: 'Customize which updates and alerts you receive about orders and services',
      billilng: {
        title: 'Billing notification',
      },
      order_status_updates: {
        title: 'Update order statuses notifications',
      },
      notification_texts: {
        title: 'Notification Settings',
      },
      emails: 'emails',
      email: 'Email',
      phones: 'phones',
      phone: 'phone',
    },
    bank: {
      title: 'Banking Information',
      description: 'Manage your banking and payment information for transactions',
      'btn-take-action': 'Action required',
      'btn-recreate-action': 'Try again',
      popups: {
        'add-bank-account': {
          title: 'Add Bank Account',
          'btn-create': 'Vierify bank account',
        },
        'verify-bank-account': {
          title: 'Verify Bank Account',
          firstDeposit: 'First Deposit',
          secondDeposit: 'Second Deposit',
          'btn-verify': 'Vierify',
        },
      },
      stripe: {
        statuses: {
          empty: {
            title: 'Save Bank account data for initiate verification process',
          },
          pending: {
            title: 'Verify your bank accaunt with microdeposit values',
          },
          rejected: {
            title: 'Verification failed',
          },
          verified: {
            title: 'Account is Verifyed',
          },
        },
        errors: {
          'undefined-verification-type': 'Problems with werification method. Please contact with support',
        },
        notifications: {
          'microdeposit-amount': 'We have sent two small deposits to this bank account.',
        },
      },
    },
    tokens: {
      title: 'Api tokens',
      description: 'Add and control API tokens for seamless software integration',
    },
    forward: {
      title: 'Email Forward',
      description: 'Set up and customize what information will be forwarded to your email address',
    },
    stripe: {
      title: 'Payment methods',
      groups: {
        'payment-methods': {
          title: 'Bank payment method',
          description: 'Payment method have priority on invoice payment',
        },
        'card-payment-methods': {
          title: 'Card payment method',
          description: 'Payment method used for pay external deliveries and for invoices if Bank account payment method didn\'t setup',
        },
      },
      popups: {
        'add-card-payment-method': {
          title: 'Add card payment method',
          'btn-create': 'Create payment method',
          'btn-subscribe': 'Pay and subscribe',
        },
        'remove-card-payment-method': {
          title: 'You are going to remove this card payment method',
          text: 'Are you sure, you want to remove this card payment method?',
          'btn-remove': 'Remove payment method',
        },
      },
    },
    communications: {
      title: 'Communications',
    },
    departments: {
      title: 'Departments',
    },
    'not-present-call-numbers': {
      title: 'Not present call numbers',
    },
    'order-type-settings': {
      title: 'Order type settings',
    },
    'vendor-info': {
      title: 'Vendor Information',
      description: '',
    },
    documents: {
      title: 'Documents',
    },
    complainvendor: {
      title: 'Complain to Vendor',
    },
    payment: {
      title: 'Payment setting',
      description: '',
    },
    'order-common': {
      title: 'Order Common',
      description: '',
    },
    'order-shippo': {
      title: 'Order Shippo',
      description: '',
    },
    'order-delivery': {
      title: 'Order Delivery',
      description: '',
    },
    'order-integration': {
      title: 'Order Integration',
      description: '',
    },
    'order-insurance': {
      title: 'Order Insurance',
      description: '',
    },
    'business-documents': {
      title: 'Business Documents',
      description: '',
    },
    navigation: {
      title: 'Navigation Settings',
      description: '',
    },
    'order-signature-reminder': {
      title: 'Order Signature Reminder',
      description: 'Set the time and number of days to remind the user about delivered orders without signatures',
    },
    balance: {
      title: 'Carrier Invoices',
    },
  },
}
