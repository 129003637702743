export default {
  'rx4route-registration': {
    title: 'Queue of users to register on the Rx4Route',
    table: {
      id: 'Id',
      name: 'Name',
      email: 'Email',
      phone: 'Phone',
      action: {
        title: 'Actions',
        button: 'Create Facility',
      },
    },
  },
}
