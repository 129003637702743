import NewOrder from './new-order'
import MyAccount from './my-account'
import ticket from './ticket'
import invoice from './invoice'
import global from './global'
import qaqc from './qaqc'
import user from './user'
import finances from './finaces'
import rx4RouteSubscriptions from './rx4route-subscriptions'
import staff from './staff'
import facilities from './facilities'
import routes from './routes'

export default {
  routes,
  global,
  ticket,
  invoice,
  qaqc,
  user,
  finances,
  staff,
  facilities,
  'new-order': NewOrder,
  'my-account': MyAccount,
  'rx4route-subscriptions': rx4RouteSubscriptions,
  address: {
    'street-address': 'Street Address',
    'manual-address': 'Input Address',
    'street-number': 'Street Number',
    'street-name': 'Street Name',
    state: 'State',
    city: 'City',
    zip: 'ZIP',
    apt: 'Apt/Suite/Floor',
    lat: 'Latitude',
    lng: 'Longitude',

    confirmChange: 'The system was not able to validate the address by this reason Rx2Go could guarantee your delivery.<br>'
      + 'Confirming this you accept all risks related with delivery and void all responsibility for Rx2Go.',
  },
  recipient: {
    'edit-popup-title': 'Edit Recipient #{id}',
  },
  common: {
    reason: 'Reason',
    facility: 'Facility',
    show_hide_column: 'Show/Hide Columns',
    'facility-hub': 'Facility Hub',
    form: {
      document: {
        empty: {
          label: 'upload your document',
        },
      },
    },
  },
  'call-queue': {
    type: {
      'upon-arrival': 'Call Upon Arrival',
      'driver-request': 'Call Driver Request',
      'late-driver': 'Call Driver Late',
      'driver-out': 'Driver Out',
      'late-after-9': 'Late After 9 PM',
      attempts: 'Call attempts confirm',
    },
  },
  'user-settings': {
    title: 'Edit User Settings',
  },
  'params-send-invoices-modal': {
    title: 'Fill parameters for send invoices',
  },
  'params-send-invoices-reminder-modal': {
    title: 'Fill parameters for send invoices reminder',
  },

  pagination: {
    'per-pages': 'Rows per page',
    back: 'Back',
    forward: 'Forward',
  },
  fields: {
    search: 'Search',
  },
}
