export default {
  'quick-payment-button': {
    title: 'Pay',
  },
  'off-session': {
    title: 'Off Session',
  },
  'payment-info-modal': {
    title: 'Payment Info',
    'default-payment-prefix': 'Pay with ',
    table: {
      fields: {
        invoice: 'Invoice',
        amount: 'Amount',
        fee: 'Fee',
        total: 'Total',
        actions: 'Actions',
      },
      actions: {
        override: 'Change',
      },
    },
  },
  'report-page': {
    breadcrumbs: 'Finances / Report',
    title: 'Common Report',
    filter: {
      from: 'From',
      to: 'To',
      client: 'Client',
      'per-page': 'Count in page',
    },
    table: {
      customer: 'Stripe Customer',
      amount: 'Amount',
      transaction: 'Stripe Transaction',
      from: 'Date From',
      to: 'Date To',
      invoices: 'Invoice Links',
    },
  },
}
