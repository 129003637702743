export default {
  tab: {
    delivery: 'Building Pictures',
    geofence: 'Check Geofence',
    apartment: 'Apartment Pictures',
    id: 'ID Pictures',
    name: 'Patient`s Name',
    signature: 'Signature',
    method: 'Delivery Method',
    fridge: 'Fridge Pictures',
    freezer: 'Freezer Pictures',
    package: 'Package Pictures',
    copay: 'Copay Pictures',
    instructions: 'Special Instructions',

    attempt_call_recipient: 'Call Recipient',
    attempt_call_pharmacy: 'Call Pharmacy',
    attempt_dispatch_note: 'Dispatch Note',
    date: 'Date to Delivery',
  },
}
