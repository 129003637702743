export default {
  'transaction-report': {
    table: {
      batch: {
        label: 'Batch',
      },
      transaction: {
        label: 'Transactions',
        fields: {
          id: 'Id',
          date: 'Date',
          amount: 'Amount',
          status: 'Status',
        },
        empty: 'Not Created',
      },
      entity: {
        label: 'Entity',
        fields: {
          id: 'Id',
          type: 'Type',
          status: 'Status',
          end: 'End',
          start: 'Start',
          amount: 'Amount',
          client: 'Client',
        },
      },
    },
  },
}
