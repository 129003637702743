export default {
  title_dashboard: 'Tickets Dashboard',
  title_q: 'Tickets Queue',
  title: 'Tickets',
  new: 'New',
  set_status: 'Set status ({count})',
  loading: 'Loading... Please wait',
  hide_column: 'Hide All',
  show_column: 'Show All',
  filter: {
    my: 'See My tickets',
    assign: 'See Assigned to Me',
    managed: 'See managed by Me',
    all: 'See All Tickets',
  },
  analytic: {
    title: 'Analytic',
    table: {
      id: 'ID',
      user: 'User',
      calls: 'Calls',
      note_left: 'Notes left',
      opened: 'Opened',
      assigned: 'Assigned',
      in_process: 'In Progress',
      ready_to_resolve: 'Ready to Resolve',
      resolved: 'Resolved',
      on_hold: 'On Hold',
      // Common
      old_status: 'Old status',
      status: 'Status',
      new_status: 'New status',
      created: 'Created',
      ticket_id: 'Ticket',
      type: 'Type',
      content: 'Content',
      entity: 'Entity',
      duration: 'Duration',
    },
  },
}
